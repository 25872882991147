<template>
  <div>
    <b-modal
      id="add-release-modal"
      title="Create Release Version"
      size="lg"
      lazy
      @shown="syncReqs"
    >
      <!-- Release Name -->
      <b-row>
        <b-col>
          <b-form-group label="Release Version" label-for="a-rel-name">
            <b-form-input id="a-rel-name" v-model="sub_obj.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Release Description -->
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="a-rel-desc">
            <b-form-textarea
              id="a-rel-desc"
              v-model="sub_obj.description"
              placeholder="Enter description for release..."
              rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Start and End Date -->
      <b-row class="mb-2">
        <b-col>
          <label for="a-release-date-start">Start Date</label>
          <b-input-group>
            <b-form-input
              id="a-release-date-start"
              v-model="sub_obj.start_date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                id="release-date-picker-start-date"
                v-model="sub_obj.start_date"
                button-only
                right
                locale="en-AU"
                aria-controls="a-release-date-start"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <label for="a-release-end-date">Release Date</label>
          <b-input-group>
            <b-form-input
              id="a-release-end-date"
              v-model="sub_obj.end_date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                id="release-date-picker-end-date"
                v-model="sub_obj.end_date"
                button-only
                right
                locale="en-AU"
                aria-controls="a-release-end-date"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!--<b-row>-->
      <!--  &lt;!&ndash; Allocations Header &ndash;&gt;-->
      <!--  <h3>-->
      <!--    Allocations-->
      <!--  </h3>-->
      <!--  <hr class="mb-2">-->
      <!--</b-row>-->
      <!--<b-row>-->
      <!--  <b-col>-->

      <!--    <vue-perfect-scrollbar-->
      <!--      :settings="perfectScrollbarSettings"-->
      <!--      class="scroll-area-release-entities"-->
      <!--    >-->

      <!--      &lt;!&ndash; Capabiliites &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-entity-->
      <!--            label="Capabilities"-->
      <!--            :entity-array="sub_obj.capabilities"-->
      <!--            modal="a-associate-release-cap-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      &lt;!&ndash; Features / Functions &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-entity-->
      <!--            label="Functions"-->
      <!--            :entity-array="sub_obj.functions"-->
      <!--            modal="a-associate-release-fn-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      &lt;!&ndash; Resources / Entities / Objects &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-entity-->
      <!--            label="Resources"-->
      <!--            :entity-array="sub_obj.objects"-->
      <!--            modal="a-associate-release-rsc-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--    </vue-perfect-scrollbar>-->
      <!--  </b-col>-->
      <!--  <b-col>-->
      <!--    <vue-perfect-scrollbar-->
      <!--      :settings="perfectScrollbarSettings"-->
      <!--      class="scroll-area-release-entities"-->
      <!--    >-->
      <!--      &lt;!&ndash; Systems &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-entity-->
      <!--            label="System Elements"-->
      <!--            :entity-array="sub_obj.systems"-->
      <!--            modal="a-associate-release-perf-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      &lt;!&ndash; Interfaces &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-interface-->
      <!--            label="Interfaces"-->
      <!--            :entity-array="sub_obj.interfaces"-->
      <!--            modal="a-associate-release-intf-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      &lt;!&ndash; Requirements &ndash;&gt;-->
      <!--      <b-row class="mb-2">-->
      <!--        <b-col>-->
      <!--          <list-group-requirement-->
      <!--            label="Requirements"-->
      <!--            :entity-array="sub_obj.requirements"-->
      <!--            modal="a-associate-release-req-modal"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--    </vue-perfect-scrollbar>-->
      <!--  </b-col>-->
      <!--</b-row>-->

      <!-- Modal Buttons -->
      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Dismiss
        </b-button>

        <b-button variant="success" :disabled="loading_status_add" @click="addRelease">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Release
          </span>

        </b-button>
      </template>
    </b-modal>

    <!--<Associator-->
    <!--  id="a-associate-release-cap-modal"-->
    <!--  title="Allocate Capability to Release"-->
    <!--  left-label="Capabilities"-->
    <!--  :left-opts="capabilities.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Capabilities"-->
    <!--  :right-opts="sub_obj.capabilities.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleaseCapability"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-obj-modal"-->
    <!--  title="Allocate Objective/Outcome to Release"-->
    <!--  left-label="Objectives"-->
    <!--  :left-opts="objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Objectives"-->
    <!--  :right-opts="sub_obj.objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleaseObjectives"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-fn-modal"-->
    <!--  title="Allocate Features/Functions to Release"-->
    <!--  left-label="Functions"-->
    <!--  :left-opts="functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Functions"-->
    <!--  :right-opts="sub_obj.functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleaseFunctions"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-rsc-modal"-->
    <!--  title="Allocate Entities/Data/Resources to Release"-->
    <!--  left-label="Entities"-->
    <!--  :left-opts="entities.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Entities"-->
    <!--  :right-opts="sub_obj.objects.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleaseEntities"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-perf-modal"-->
    <!--  title="Allocate System Element to Release"-->
    <!--  left-label="System Elements"-->
    <!--  :left-opts="systems.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="System Elements"-->
    <!--  :right-opts="sub_obj.systems.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleasePerformers"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-intf-modal"-->
    <!--  title="Allocate Interfaces to Release"-->
    <!--  left-label="Interfaces"-->
    <!--  :left-opts="interfaces.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Interfaces"-->
    <!--  :right-opts="sub_obj.interfaces.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkReleaseInterfaces"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="a-associate-release-req-modal"-->
    <!--  title="Allocate Requirements to Release"-->
    <!--  left-label="Requirements"-->
    <!--  :left-opts="requirements.map(x=>{return{value:x.id, text:x.display_id+' - '+x.text, display_id: x.display_id, object_text: x.text, priority: x.priority}})"-->
    <!--  right-label="Requirements"-->
    <!--  :right-opts="sub_obj.requirements.map(x=>{return{value:x.id, text:x.display_id+' - '+x.object_text, display_id: x.display_id, object_text: x.object_text, priority: x.priority}})"-->
    <!--  @ok="linkReleaseRequirements"-->
    <!--/>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BButton, VBModal } from 'bootstrap-vue'
// import Associator from '@/components/Forms/M_Associator.vue'
// import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
// import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
// import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BButton,
    // Associator,
    // ListGroupEntity,
    // ListGroupInterface,
    // ListGroupRequirement,
    // VuePerfectScrollbar,
  },
  directives: {
    'b-modal': VBModal,
  },
  data: () => ({
    sub_obj: {
      id: '',
      description: '',
      updated: '',
      name: '',
      systems: [],
      functions: [],
      objectives: [],
      capabilities: [],
      objects: [],
      interfaces: [],
      requirements: [],
      start_date: '',
      end_date: '',
    },
    requirements: [],
    loading_status_add: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
  }),
  computed: {
    ...mapState({
      capabilities: state => state.domainModel.capabilities,
      objectives: state => state.canvas.objectives,
      functions: state => state.domainModel.functions,
      entities: state => state.domainModel.entities,
      systems: state => state.domainModel.systems,
      interfaces: state => state.interfaces.interfaces,
    }),
  },
  methods: {
    syncReqs() {
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } }).then(({ data }) => {
        this.requirements = data
      })
    },
    addRelease() {
      this.loading_status_add = true
      this.$store.dispatch('releases/addRelease', this.sub_obj)
        .then(() => {
          this.loading_status_add = false
          this.$bvModal.hide('add-release-modal')
        })
    },
    linkReleaseObjectives(evt, objs) {
      evt.preventDefault()
      this.sub_obj.objectives = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-obj-modal')
    },
    linkReleaseCapability(evt, shs) {
      evt.preventDefault()
      this.sub_obj.capabilities = shs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-cap-modal')
    },
    linkReleaseFunctions(evt, shs) {
      evt.preventDefault()
      this.sub_obj.functions = shs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-fn-modal')
    },
    linkReleaseEntities(evt, ents) {
      evt.preventDefault()
      this.sub_obj.objects = ents.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-rsc-modal')
    },
    linkReleasePerformers(evt, ents) {
      evt.preventDefault()
      this.sub_obj.systems = ents.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-perf-modal')
    },
    linkReleaseInterfaces(evt, ents) {
      evt.preventDefault()
      this.sub_obj.interfaces = ents.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.$bvModal.hide('a-associate-release-intf-modal')
    },
    linkReleaseRequirements(evt, ents) {
      evt.preventDefault()
      this.sub_obj.requirements = ents.map(x => ({
        id: x.value,
        display_id: x.display_id,
        priority: x.priority,
        object_text: x.object_text,
      }))
      this.$bvModal.hide('a-associate-release-req-modal')
    },
  },
}
</script>

<style lang="scss">
#modal__ReleaseEdit {
  // b-modal size must be "xl" for this to apply.
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.scroll-area-release-entities {
  max-height: 60vh;
}
</style>
