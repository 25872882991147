<template>
  <div>
    <b-modal
      id="modal__ReleaseEdit"
      title="Update Release"
      size="xl"
      :ok-title="loading ? 'Updating...' : 'Update Release'"
      :ok-disabled="loading"
      ok-variant="success"
      cancel-title="Discard"
      cancel-variant="outline-secondary"
      lazy
      no-fade
      no-close-on-backdrop
      @show="getData"
      @ok.prevent="onSubmit"
    >
      <!--<pre>{{ capabilities }}</pre>-->
      <!-- Linked Entities Header -->
      <h3>
        Release
      </h3>
      <hr class="mb-2">
      <b-row>
        <b-col cols="6">
          <!-- Release Name -->
          <b-row>
            <b-col>
              <b-form-group label="Release name" label-for="rel-name">
                <b-form-input
                  id="rel-name"
                  v-model="sub_obj.name"
                  placeholder="Enter the name of the Release..."
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Release Description -->
          <b-row>
            <b-col>
              <b-form-group label="Description" label-for="rel-desc">
                <b-form-textarea
                  id="rel-desc"
                  v-model="sub_obj.description"
                  placeholder="Give a brief description of the Release..."
                  rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Start and End Date -->
          <b-row>
            <b-col>
              <label for="release-date-start">Start date</label>
              <b-input-group>
                <b-form-input
                  id="release-date-start"
                  v-model="sub_obj.start_date"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="sub_obj.start_date"
                    button-only
                    right
                    locale="en-AU"
                    aria-controls="release-date-start"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <label for="release-end-date">Release date</label>
              <b-input-group>
                <b-form-input
                  id="release-end-date"
                  v-model="sub_obj.end_date"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="sub_obj.end_date"
                    button-only
                    right
                    locale="en-AU"
                    aria-controls="release-end-date"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <h4 class="text-primary">
            Warnings
          </h4>
          <p class="text-success">
            There are no dependency warnings for this release
          </p>
        </b-col>
      </b-row>

      <hr class="mb-2">

      <h3>Assignment</h3>
      <b-row v-if="isDataLoaded">
        <!-- Allocations Header -->
        <b-col>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scroll-area-release-entities"
          >

            <!-- Capabiliites -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Capabilities"
                  :entity-array="sub_obj.capabilities"
                  modal="associator-generic-capabilities-edit"
                />
              </b-col>
            </b-row>

            <!-- Requirements -->
            <b-row class="mb-2">
              <b-col>
                <list-group-requirement
                  label="Requirements"
                  :entity-array="sub_obj.requirements"
                  modal="associate-requirement-release-modal"
                />
              </b-col>
            </b-row>

            <!-- Functions -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Functions"
                  :entity-array="sub_obj.functions"
                  modal="associator-generic-functions-edit"
                />
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </b-col>

        <b-col>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scroll-area-release-entities"
          >
            <!-- Entities -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Entities"
                  :entity-array="sub_obj.objects"
                  modal="associator-generic-entities-edit"
                />
              </b-col>
            </b-row>

            <!-- Systems -->
            <b-row class="mb-2">
              <b-col>
                <list-group-release-entity
                  label="System Elements"
                  :entity-array="sub_obj.systems"
                  modal="associator-generic-systems-edit"
                />
              </b-col>
            </b-row>

            <!-- Interfaces -->
            <b-row class="mb-2">
              <b-col>
                <list-group-interface
                  label="Interfaces"
                  :entity-array="sub_obj.interfaces"
                  modal="associator-generic-interfaces-edit"
                />
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </b-col>
      </b-row>
      <b-row v-else>
        <h1 class="text-center text-warning w-100">
          <b-spinner />
        </h1>
      </b-row>
    </b-modal>

    <div id="modal__ReleaseEditModalsContainer">
      <!-- **** Modals **** -->
      <!-- Requirements -->
      <AssociateReqReleaseModal
        :requirement-ids="sub_obj.requirements"
        @associated="linkReleaseRequirements"
      />

      <!-- Capabilities -->
      <associator-generic
        name="Capabilities"
        suffix="-edit"
        :associated-items="sub_obj.capabilities.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allCapabilities.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkReleaseCapability"
      />

      <!-- Objectives -->
      <!-- Not needed? -->
      <associator-generic
        name="Objectives"
        suffix="-edit"
        :associated-items="sub_obj.objectives.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="objectives.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkReleaseObjectives"
      />

      <!-- Functions -->
      <associator-generic
        name="Functions"
        suffix="-edit"
        :associated-items="sub_obj.functions.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allFunctions.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkReleaseFunctions"
      />

      <!-- Entities -->
      <associator-generic
        name="Entities"
        suffix="-edit"
        :associated-items="sub_obj.objects.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allEntities.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkReleaseEntities"
      />

      <!-- System Elements -->
      <associator-generic
        name="Systems"
        suffix="-edit"
        :associated-items="sub_obj.systems.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
              release_status: item.release_status ? item.release_status : 'Assigned to Release',
            },
            text: item.name,
          }
        })"
        :all-items="allSystems.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
              release_status: 'Assigned to Release',
            },
            text: item.name,
          }
        })"
        @associated="linkReleasePerformers"
      />

      <!-- Interfaces -->
      <associator-generic
        name="Interfaces"
        suffix="-edit"
        :associated-items="sub_obj.interfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        :all-items="allInterfaces.map(item => {
          return {
            value: {
              id: item.id,
              toSortBy: item.name,
            },
            text: item.name,
          }
        })"
        @associated="linkReleaseInterfaces"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import AssociateReqReleaseModal from '@/components/Releases/Modals/AssociateReqReleaseModal.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupReleaseEntity from '@/components/Forms/ListGroups/ListGroupReleaseEntity.vue'
import ListGroupInterface from '@/components/Forms/ListGroups/ListGroupInterface.vue'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    AssociatorGeneric,
    AssociateReqReleaseModal,
    ListGroupEntity,
    ListGroupReleaseEntity,
    ListGroupInterface,
    ListGroupRequirement,
    VuePerfectScrollbar,
  },
  props: {
    interfaces: {
      type: Array,
      required: true,
    },
    isDataLoaded: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    sub_obj: {
      id: '',
      description: '',
      updated: '',
      name: '',
      systems: [],
      functions: [],
      objectives: [],
      capabilities: [],
      objects: [],
      interfaces: [],
      requirements: [],
      start_date: '',
      end_date: '',
    },
    allCapabilities: [],
    allFunctions: [],
    allEntities: [],
    allSystems: [],
    allInterfaces: [],
    loading: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
  }),
  computed: {
    ...mapState({
      selectedRelease: state => state.releases.selectedRelease,
      capabilities: state => state.domainModel.capabilities,
      objectives: state => state.canvas.objectives,
      functions: state => state.domainModel.functions,
      entities: state => state.domainModel.entities,
      systems: state => state.domainModel.systems,
    }),
  },
  watch: {
    default(newVal) {
      this.value = newVal
    },
    selectedRelease(newVal) {
      console.log('New Val: ', newVal)
      const t = this.sub_obj
      t.id = newVal.id
      t.name = newVal.name
      t.description = newVal.description
      t.updated = newVal.updated
      t.objectives = newVal.objectives
      t.systems = newVal.systems
      t.capabilities = newVal.capabilities
      t.functions = newVal.functions
      t.objects = newVal.objects
      t.interfaces = newVal.interfaces
      t.requirements = newVal.requirements
      t.start_date = newVal.start_date
      t.end_date = newVal.end_date
    },
  },
  mounted() {
    this.removeDuplicatesForAssociators()
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store
        .dispatch('releases/updateRelease', this.sub_obj)
        .then(() => {
          this.$bvModal.hide('modal__ReleaseEdit')
          this.$store.dispatch('domainModel/getComponentsSimple')
          this.$store.dispatch('interfaces/fetchInterfaces')
        })
        .finally(() => { this.loading = false })
    },
    getData() {
      this.removeDuplicatesForAssociators()
      this.$store.dispatch('domainModel/getComponentsSimple')
    },
    removeDuplicatesForAssociators() {
      // Remove already associated elements from the all arrays
      // -- Capabilities
      this.allCapabilities = this.capabilities.map(x => ({ ...x }))
      this.sub_obj.capabilities.forEach((elementA, indexA) => {
        this.allCapabilities.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allCapabilities.splice(indexB, 1)
          }
        })
      })
      // -- Functions
      this.allFunctions = this.functions.map(x => ({ ...x }))
      this.sub_obj.functions.forEach((elementA, indexA) => {
        this.allFunctions.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allFunctions.splice(indexB, 1)
          }
        })
      })
      // -- Entities
      this.allEntities = this.entities.map(x => ({ ...x }))
      this.sub_obj.objects.forEach((elementA, indexA) => {
        this.allEntities.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allEntities.splice(indexB, 1)
          }
        })
      })
      // -- Systems
      this.allSystems = this.systems.map(x => ({ ...x }))
      this.sub_obj.systems.forEach((elementA, indexA) => {
        this.allSystems.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allSystems.splice(indexB, 1)
          }
        })
      })
      // -- Interfaces
      this.allInterfaces = this.interfaces.map(x => ({ ...x }))
      this.sub_obj.interfaces.forEach((elementA, indexA) => {
        this.allInterfaces.forEach((elementB, indexB) => {
          if (elementA.id === elementB.id) {
            this.allInterfaces.splice(indexB, 1)
          }
        })
      })
    },
    linkReleaseObjectives(objs) {
      const temp = this.findItemById(objs, this.objectives)

      this.sub_obj.objectives = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkReleaseCapability(shs) {
      const temp = this.findItemById(shs, this.capabilities)

      this.sub_obj.capabilities = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkReleaseFunctions(func) {
      const temp = this.findItemById(func, this.functions)

      this.sub_obj.functions = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkReleaseEntities(ents) {
      const temp = this.findItemById(ents, this.entities)

      this.sub_obj.objects = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkReleasePerformers(ents) {
      // get the items that are already in the list and new ones
      const existing = this.sub_obj.systems.filter(e => ents.includes(e.id))
      const newEnts = this.systems.filter(e => ents.includes(e.id) && !(this.sub_obj.systems.map(x => x.id).includes(e.id))).map(x => ({
        release_status: 'Assigned to Release', ...x,
      }))
      console.log('Existing things to allocate: ', existing)
      console.log('New things to allocate: ', newEnts)
      this.sub_obj.systems = [...existing, ...newEnts]
      // this.removeDuplicatesForAssociators()
    },
    linkReleaseInterfaces(interfaces) {
      const temp = this.findItemById(interfaces, this.interfaces)

      this.sub_obj.interfaces = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
      this.removeDuplicatesForAssociators()
    },
    linkReleaseRequirements(reqs) {
      this.sub_obj.requirements = reqs.map(x => ({
        id: x.id,
        display_id: x.display_id,
        object_text: x.object_text,
      }))
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
  },
}
</script>

<style lang="scss">
#modal__ReleaseEdit {
  // b-modal size must be "xl" for this to apply.
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.scroll-area-release-entities {
  max-height: 60vh;
}
</style>
