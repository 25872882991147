<template>
  <b-modal
    id="associate-requirement-release-modal"
    title="Associate Requirements to Release"
    size="xl"
    ok-title="Associate Requirements"
    ok-variant="success"
    cancel-title="Cancel"
    cancel-variant="outline-secondary"
    no-close-on-esc
    no-close-on-backdrop
    @shown="resetFields"
    @ok="onSubmit"
  >
    <div class="d-flex m-25">
      <!-- All Requirements + Spec Selector column -->
      <div class="w-50 d-flex flex-column">
        <!-- Spec Selector Title -->
        <div id="spec-selection" class="mb-1">
          <h6 class="d-inline">
            Specifications
            <b-button
              size="sm"
              variant="flat-info"
              class="px-0"
              @click="fetchSpecifications"
            >
              <feather-icon icon="RefreshCcwIcon" class="pr-25" />
            </b-button>
          </h6>
          <span class="float-right font-small-2 text-info">
            Clear selection to show all requirements
          </span>
          <!-- Spec Selector -->
          <v-select
            v-model="selectedSpecification"
            :disabled="loadingRequirements"
            :loading="loadingRequirements"
            label="title"
            :options="specifications"
          >
            <template #spinner="{ loadingRequirements }">
              <div
                v-if="loadingRequirements"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
          </v-select>
        </div>

        <!-- Requirements Selector -->
        <div class="flex-grow-1 d-flex flex-column">
          <div class="w-100 d-inline-flex justify-content-between">
            <h6>
              <b-badge variant="info" class="mr-25">
                {{ requirements.length }}
              </b-badge>
              <span v-if="requirements.length !== 1">Requirements</span>
              <span v-else>Requirement</span>
            </h6>
            <b-button-group style="width: 30%">
              <b-button
                size="sm"
                variant="flat-primary"
                @click="fetchAvailableRequirements(selectedSpecification)"
              >
                <feather-icon icon="RefreshCcwIcon" class="pr-25" />
                Refresh
              </b-button>
              <b-button
                size="sm"
                variant="flat-primary"
                @click="selectedRequirements = []"
              >
                Clear selected
              </b-button>
            </b-button-group>
          </div>
          <b-form-input
            v-model="requirementSearch"
            placeholder="Search..."
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
          />
          <b-form-select
            v-model="selectedRequirements"
            :options="requirements"
            :disabled="loadingRequirements"
            class="h-100"
            style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
            multiple
          />
        </div>
      </div>

      <!-- Allocate Button -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          v-b-popover.hover.top="'Allocate Requirements for association'"
          variant="flat-success"
          @click="addRequirement"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>
      </div>

      <!-- Allocated Requirements -->
      <div class="w-50">
        <h6>
          <b-badge variant="info" class="mr-25">
            {{ associatedRequirements.length }}
          </b-badge>
          <span v-if="associatedRequirements.length !== 1">Linked Requirements</span>
          <span v-else>Linked Requirement</span>
        </h6>
        <vue-perfect-scrollbar
          v-if="associatedRequirements.length > 0"
          class="scroll-area"
          :settings="{
            maxScrollbarLength: 60,
            wheelPropagation: false,
          }"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(req, index) in associatedRequirements"
              :key="index"
            >
              <div class="d-flex flex-column">
                <!-- Trace display_id & Link Type selection -->
                <div class="d-flex justify-content-between pb-0">
                  <div class="pt-25 pl-25 mb-0 pb-0">
                    <abbr
                      v-b-popover.hover.bottom.html="`${req.object_text}`"
                      :title="req.display_id"
                      class="mr-1 text-nowrap font-weight-bolder text-primary"
                    >
                      {{ req.display_id }}
                    </abbr>
                  </div>
                  <div>
                    {{ req.object_text }}
                  </div>
                  <div class="w-50 d-inline-flex flex-row-reverse">
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      class="p-25 ml-3"
                      @click="removeRequirement(req)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
        <div v-else>
          <p class="mt-1 ml-1 text-muted">
            No linked Requirements...
          </p>
        </div>
        <!--<pre>-->
        <!--  {{ associatedRequirements }}-->
        <!--</pre>-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'AssociateReqReleaseModal',
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    requirementIds: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    // Specification selection --
    const selectedSpecification = ref('')
    const specifications = ref([])
    const fetchSpecifications = () => {
      specifications.value = store.state.specifications.specifications
      // Populate the Specification options if it isn't already
      if (specifications.value.length === 0) {
        store
          .dispatch('specifications/fetchSpecificationsSimple')
          .then(() => {
            specifications.value = store.state.specifications.specifications
          })
      }
    }
    fetchSpecifications()

    watch(selectedSpecification, newSpec => {
      if (newSpec) {
        fetchAvailableRequirements(newSpec)
      } else {
        fetchAvailableRequirements()
      }
    })
    // -- ./specification selection

    // Available requirements --
    const selectedRequirements = ref([])
    const requirementsCoreList = ref([])
    const requirements = ref([])
    const loadingRequirements = ref(false)
    const requirementSearch = ref('')
    const associatedRequirements = ref([])
    const fetchAvailableRequirements = specification => {
      loadingRequirements.value = true
      store
        .dispatch('requirements/updateRequirementOptions', specification?.id)
        .then(() => {
          requirementsCoreList.value = []
          store.state.requirements.requirement_options.forEach(a => {
            if (!props.requirementIds.find(b => b.id === a.value)) {
              requirementsCoreList.value.push(a)
            }
          })
          requirements.value = requirementsCoreList.value
        })
        .catch(e => {
          console.error(e)
          fetchAvailableRequirements()
        })
        .finally(() => {
          loadingRequirements.value = false
        })
    }
    function setAssociatedRequirements() {
      if (props.requirementIds.length > 0) {
        props.requirementIds.forEach(x => {
          if (associatedRequirements.value.filter(tI => tI.id === x.value).length === 0) {
            const item = {
              id: x.id,
              display_id: x.display_id,
              object_text: x.object_text,
            }
            associatedRequirements.value.push(item)
          }
        })
      }
    }
    setAssociatedRequirements()

    watch(requirementSearch, newValue => {
      requirements.value = requirementsCoreList.value
      if (newValue !== '') {
        // Filter Requirements list by text (display_id & object_text) or value (requirement id)
        requirements.value = requirements.value.filter(a => (
          a.text.toLowerCase().includes(newValue.toLowerCase())
          || a.value.toLowerCase() === newValue.toLowerCase()
        ))
      }
    })
    // -- ./available requirements

    const addRequirement = () => {
      // Add the Requirement to 'Associated' list if it doesn't already exist.
      selectedRequirements.value.forEach(
        x => {
          const requirement = requirements.value.find(y => y.value === x)
          if (associatedRequirements.value.filter(tI => tI.id === x).length === 0) {
            const item = {
              id: requirement.value,
              display_id: requirement.display_id,
              object_text: requirement.object_text,
            }
            associatedRequirements.value.push(item)
          }
        },
      )
    }
    const removeRequirement = async req => {
      const userConfirmation = await context.root.$swal({
        title: 'Are you sure?',
        text: "This will un-associate this requirement after you click 'Associate Requirements'",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it',
        cancelButtonText: 'No, keep it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })

      if (userConfirmation.isConfirmed === false) {
        // return // User declined, do nothing.
      }

      const reqToRemoveById = associatedRequirements.value.findIndex(obj => obj.id === req.id)
      associatedRequirements.value.splice(reqToRemoveById, 1)
    }

    function resetFields() {
      fetchAvailableRequirements()
      selectedSpecification.value = ''
      fetchSpecifications()
      associatedRequirements.value = []
      setAssociatedRequirements()
    }
    function onSubmit() {
      context.emit('associated', associatedRequirements.value)
    }

    return {
      // Setup
      specifications,
      fetchSpecifications,
      requirements,
      loadingRequirements,
      requirementSearch,
      fetchAvailableRequirements,

      // Selected items
      selectedSpecification,
      selectedRequirements,

      // Associated
      associatedRequirements,
      addRequirement,
      removeRequirement,

      // Submit
      onSubmit,
      resetFields,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
